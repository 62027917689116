<template>
    <div class="mobile-symbol-page">
        <section class="homepage-market-sm d-lg-none d-md-none">
                <dl> 
                <dd class="title" :class="['fr','de'].includes($i18n.locale)?'small-font':''">
                    <dl>{{ $t('futures.popular-currency') }}</dl>
                    <dl>{{ $t('home.price') }}</dl>
                    <em>{{ $t('home.24h_change') }}</em>
                </dd>
                    <dd v-for="(symbol, index) in symbols" :key="index">
                        <dl>
                            <dt class="text-uppercase d-flex align-items-center token-name">
                                <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                <div>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</div>
                            </dt>
                        </dl>
                        <dl>
                            <dt growing-ignore="true">{{ symbol.price }}</dt>
                            <dd growing-ignore="true">${{ symbol.price_change }}</dd>
                        </dl>
                        <em :class="symbol.up ? 'bg-up' : 'bg-down'" growing-ignore="true">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</em>
                    </dd>
                </dl>
        </section>
    </div>
</template>
<script>
import TokenIcon from '../../Components/TokenIcon.vue';
export default {
    components: { TokenIcon },
    props: [ 'symbols'],
    methods: {
        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        }
    }
};
</script>
<style scoped>
.mobile-symbol-page{
    background-color: #fff;
    border-radius: 8px;
}
</style>