<template>
    <div class="w-100 text-center prompted-symbol">
        <div class="token-icon-wrapper d-flex">
            <token-icon :symbol="symbol" />
            <h2 class="bite-name">{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</h2>
        </div>
        <img class="image-size" v-if="symbol.up" src="@/assets/images/eu1/up-trade.png" />
        <img class="image-size" v-else src="@/assets/images/eu1/down-trade.png" />
        <div class="symbol-content d-flex">
            <div class="price">$ {{ symbol.price }}</div>
            <div :class="symbol.up ? 'up' : 'down'">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</div>
        </div>
    </div>
</template>

<script>
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['symbol'],

    methods: {
        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        }
    }
};
</script>

<style scoped>
.page-top .prompted-symbol{
    padding: 1.2rem;
    background: #fff;
    border-radius: 10px;
}
.image-size{
    width: 145px;
    height: 45px;
    margin-top: 18px;
}
.bite-name{
    font-size: 18px;
    line-height: 30px;
    margin-left: 12px;
}
.symbol-content {
    color: #161616;
    border-radius: 0.5rem;
    padding-top: 1rem;
    line-height: 2;
    justify-content: space-between;
}
.symbol-content .price{
    background-color: #fff;
}
.symbol-content h2 {
    font-size: 1.25rem;
}

.symbol-content .up {
    color: var(--up);
}
.symbol-content .up::before {
    content: url('@/assets/images/rising-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}
.symbol-content .down {
    color: var(--down);
}
.symbol-content .down::before {
    content: url('@/assets/images/falling-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}
@media (max-width: 768px) {
    .image-size {
        width: 130px;
    }
}
</style>