<template>
    <form action="javascript:void(0);" method="post" class="login-form" @submit="do_submit">
        <h1>{{ $t('reset_password.title') }}</h1>
        <div class="page-top-nav mb-3" v-if="sysconfig.phoneSupported && sysconfig.emailSupported">
            <li :class="{ active: !emailVCode }">
                <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
            </li>
            <li :class="{ active: emailVCode }">
                <a @click="emailVCode = true" href="javascript:;">{{ $t('register.label_email') }}</a>
            </li>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <label class="form-label">{{ $t(emailVCode?"register.label_email":'reset_password.label_account')}}</label>
                </div>
            </div> 
            <template v-if="emailVCode">
                <!-- Email account -->
                <!-- Required by the interface -->
                <input type="hidden" name="RegionCode" value="+1" />
                <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control"  />
            </template>
            <div v-else  class="d-flex">
                <region-selector v-bind:regions="regions" :regionCode="regionCode" @change="onRegionCodeChanged" />
                <div class="flex-fill">
                    <input
                        name="Name"
                        ref="accountInput"
                        v-model="account"
                        pattern="\d*"
                        type="text"
                        class="form-control phone round-right"
                        data-val="true"
                        data-val-required="*"
                        />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col">
                    <label class="form-label">{{ $t('reset_password.label_password')}}</label>
                </div>
            </div> 
            <div class="password-box">
                <input
                    name="Password"
                    v-model="password"
                    :type="showPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                />
                <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <label class="form-label">{{ $t('reset_password.label_confirm_password')}}</label>
                </div>
            </div> 
            <div class="password-box">
                <input
                    name="ConfirmPassword"
                    v-model="confirmPassword"
                    :type="showConfirmPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    data-val-equalto-other="Password"
                    data-val-equalto="*"
                />
                <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <sms-verification :regionCode="regionCode" :account="account" action="reset-password" />
        <div class="form-group">
            <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
                {{ $t('reset_password.title') }}
            </button>
        </div>
    </form>
</template>
<style scoped>
.page-top-nav{
    display: flex;
    margin-bottom: 2rem !important;
    align-items: center;
}
.page-top-nav li{
    width: 50%;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}
.page-top-nav li.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4.25rem;
    height: .125rem;
    background-color: #041438;
}
.page-top-nav li a {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    height: 100%;
    font-size: 15px;
}
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
#login-page button.btn-submit {
    margin: 1rem 0;
    display: block;
    width: 100%;
    padding: .75rem 0;
    color: #fff;
    background: #333333;
    border-radius: 6px 6px 6px 6px;
}
.help-block {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #7F8490;
    font-size: 14px;
}
.form-group {
    margin-bottom: 1rem;
}
.help-link {
    font-size: 12px;
    color: #7F8490;
}
.login-form .form-group .form-control {
    font-size: 13px;
    height: 45px;
}
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';
export default {
    components: { RegionSelector },
    props: ['regions'],
    data() {
        return {
            emailVCode: false,
            account: null,
            regionCode: null,
            password: null,
            confirmPassword: null,

            showInputs: true,

            submitting: false,
            completed: false,
            showPassword:false,
            showConfirmPassword:false
        };
    },

    mounted() {
        $.resetValidators();
    },
    watch: {
        emailVCode: function () {
            Vue.nextTick(() => {
                $.resetValidators();
            });
        }
    },
    methods: {
        // goNextPage: function (e) {
        //     const frm = $(e.target);
        //     if (frm.valid()) {
        //         this.showInputs = false;
        //     }
        //     return false;
        // },

        // goPrevPage: function () {
        //     this.showInputs = true;
        // },

        do_submit: function (e) {
            const frm = $(e.target);
            const self = this;
            if(this.confirmPassword != this.password){
                $.top_error(self.$t('withdraw_password.err_different_passwords'));
                return
            }
            if (frm.valid()) {
                this.submitting = true;

                const data = frm.serialize();
                $.callPostApi(self, '/api/v1/account/resetpassword', data)
                    .then((json) => {
                        if (json && json.errcode === 0) {
                            // redirect to home page
                            self.completed = true;

                            setTimeout(function () {
                                self.$router.push('/user');
                            }, 500);
                        } else {
                            $.top_error(json.errmsg);
                        }
                    })
                    .catch(function (err) {
                        $.top_error(self.$t('general.operation_error'));
                    })
                    .then(() => {
                        self.submitting = false;
                    });
            }
        },

        focusAccount: function () {
            this.$refs.accountInput.focus();
        },

        onRegionCodeChanged: function (val) {
            this.regionCode = val;
        }
    }
};
</script>