<template>
    <div ref="updatePointsModal" class="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="javascript:void(0);" method="post" @submit="doUpdateStopPoints">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ $t('futures.label_set_stop_points') }}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <input type="hidden" name="OrderId" :value="order.orderId" />
                            <div class="form-control-static">
                                <p>
                                    <b>{{ order.numHands }} {{ $t('futures.label_unit') }} {{ order.symboNname }}</b>
                                    <span v-if="order.buyUp" class="color-up"> {{ $t('futures.label_buy_up') }} <i class="fa fa-arrow-up"></i> </span>
                                    <span v-else class="color-down"> {{ $t('futures.label_buy_down') }} <i class="fa fa-arrow-down"></i> </span>
                                </p>
                                <div>{{ new Date(order.timeCreated).formatDateTime() }}</div>
                                <hr />
                                <div>
                                    <label class="text-muted">{{ $t('futures.order_margin_pct') }}</label> {{ 100 / order.leverage }}%，
                                    <label class="text-muted">{{ $t('futures.order_fee') }}</label> {{ order.totalFee }} USDT
                                </div>
                                <div>
                                    <label class="text-muted">{{ $t('futures.order_create_price') }}</label>
                                    <span v-if="order.marketPrice === true">{{ $t('futures.label_market_price') }}</span>
                                    <span v-else>{{ order.createPrice }}</span>
                                </div>
                                <div>
                                    <label class="text-muted">{{ $t('futures.order_avg_price') }}</label> {{ order.matchPrice }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label"> {{ $t('futures.order_stop_profit_point') }} </label>
                            <input
                                name="StopProfitPoint"
                                type="text"
                                v-model="stopProfitPoint"
                                class="form-control"
                                data-val="true"
                                data-val-number="*"
                                data-val-range="*"
                                :data-val-range-max="order.buyUp ? 2147483647 : order.matchPrice"
                                :data-val-range-min="order.buyUp ? order.matchPrice : 0.00000001"
                                data-val-required="*" />
                        </div>
                        <div class="form-group">
                            <label class="control-label"> {{ $t('futures.order_stop_loss_point') }} </label>
                            <input
                                name="StopLossPoint"
                                type="text"
                                v-model="stopLossPoint"
                                class="form-control"
                                data-val="true"
                                data-val-number="*"
                                data-val-range="*"
                                :data-val-range-max="order.buyUp ? order.matchPrice : 2147483647"
                                :data-val-range-min="order.buyUp ? 0.00000001 : order.matchPrice"
                                data-val-required="*" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="submitting" :class="{ 'btn-loading': submitting }">{{ $t('general.confirm') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            // The original order.
            order: {},

            // The stop points.
            stopProfitPoint: '',
            stopLossPoint: '',

            submitting: false,

            // The popup confirmation modal.
            popupModal: null
        };
    },

    mounted() {
        this.popupModal = new Modal(this.$refs.updatePointsModal);
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        openModal: function (order) {
            if (!order || typeof order.buyUp !== 'boolean') {
                throw new Error('Invalid parameter');
            }

            this.order = order;
            this.stopProfitPoint = order.stopProfitPoint >= 0.000000001 ? order.stopProfitPoint : '';
            this.stopLossPoint = order.stopLossPoint >= 0.000000001 ? order.stopLossPoint : '';
            const self = this;

            Vue.nextTick(() => {
                $.resetValidators();
                // $(self.$refs.updatePointsModal).modal('show');
                self.popupModal.show();
            });
        },

        doUpdateStopPoints: function (e) {
            const frm = $(e.target);
            if (frm.valid()) {
                const self = this;
                const data = frm.serialize();

                self.submitting = true;
                function func() {
                    $.callPostApi(self, '/api/v1/futures/updatestoppoints', data)
                        .then(function (json) {
                            self.submitting = false;

                            if (json && json.errcode === 0) {
                                $.top_alert(self.$t('general.operation_succeeded'));
                                // $(self.$refs.updatePointsModal).modal('hide');
                                self.popupModal.hide();

                                // Notify updates of the order.
                                self.$emit('order-updated');
                                // self.$router.push(self.$route.path);
                            } else {
                                // Error code:
                                // 1000: Invalid price for the stop points
                                //
                                $.top_error(json.errcode === 1000 ? self.$t('futures.invalid_stop_points') : json.errmsg);
                            }
                        })
                        .catch(function () {
                            self.submitting = false;
                            $.top_error(self.$t('general.operation_error'));
                        });
                }
                setTimeout(func, 200);
            }
        }
    }
};
</script>