<template>
    <section>
        <table class="table list-table stock-list-table">
            <thead>
                <tr>
                    <th>{{ $t('balance.title_time') }}</th>
                    <th>{{ $t('balance.title_currency') }}</th>
                    <th class="text-right">{{ $t('balance.title_balance') }}</th>
                    <th class="text-right">{{ $t('balance.title_frozen') }}</th>
                    <th class="text-right">{{ $t('balance.title_description') }}</th>
                </tr>
            </thead>
            <tbody v-if="records && records.length > 0">
                <tr v-for="rec in records" v-bind:key="rec.id">
                    <td>{{ new Date(rec.timeCreated).formatDateTime() }}</td>
                    <td>{{ getTokenName(rec.currency) }}</td>
                    <td class="text-right">
                        <!-- Process data to 6 decimal places -->
                        <div>{{ parseFloat(rec.balanceChange.toFixed(7)) <= 0.000000 ? 0 : (rec.balanceChange > 1 ? rec.balanceChange.toFixed(6) : parseFloat(rec.balanceChange.toFixed(7))) }}</div>
                        <div :class="{ 'color-up': rec.currentBalance > 0, 'color-down': rec.currentBalance <= 0 }">
                            {{ parseFloat(rec.currentBalance.toFixed(6))}}
                        </div>
                    </td>
                    <td class="text-right">
                        <div>{{ rec.currentFrozen }}</div>
                        <div v-if="rec.frozenChange != 0" :class="{ 'color-up': rec.frozenChange > 0, 'color-down': rec.frozenChange < 0 }">
                            {{ rec.frozenChange > 0 ? '+' + rec.frozenChange : rec.frozenChange }}
                        </div>
                    </td>
                    <!--
                    It was asked to display the original description text for deposit records.
                    20: normal deposits
                    26: manual deposits
                    -->
                    <td class="text-right">{{ rec.description && (rec.type === 20 || rec.type === 26) ? rec.description : translate(rec.type, rec.description) }}</td>
                </tr>
            </tbody>
        </table>

        <loading-indicator v-if="!records" />
        <div v-else-if="records.length === 0" class="no-data-indicator m-5">{{ $t('general.no_data') }}</div>
        <div v-else class="d-flex justify-content-center">
            <button v-if="prevUids.length > 0" class="btn btn-default" v-on:click="goPrevPageAsync()">{{ $t('balance.label_prev_page') }}</button>
            <button v-if="hasMoreRecords" class="btn btn-default" v-on:click="goNextPageAsync()">{{ $t('balance.label_next_page') }}</button>
        </div>
    </section>
</template>

<style scoped>
.btn {
    margin: 1rem 0;
}

.table td,
.table th {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    word-break: break-word;
}
</style>

<script>
const bct_map = {};

export default {
    data() {
        return {
            records: null,

            hasMoreRecords: false,
            until_uid: 0,

            // An array to store starting uids for previous pages.
            prevUids: [],

            // The current language code.
            lang: null
        };
    },

    watch: {
        '$i18n.locale': function () {
            // Always use lower case to read customized balance change types.
            this.lang = (this.$i18n.locale || 'en').toLowerCase();
        }
    },

    created() {
        // Remember all balance change types.
        $(this.sysconfig.bcts).each((_, val) => {
            bct_map[val] = true;
        });

        // Start to query records from server.
        this.goNextPageAsync();

        // Always use lower case to read customized balance change types.
        this.lang = (this.$i18n.locale || 'en').toLowerCase();
    },

    methods: {
        ///////////////////////////////////////////////////////////////////////////////////////////
        // Query records
        ///////////////////////////////////////////////////////////////////////////////////////////
        goNextPageAsync: async function () {
            // Remember the id of the first record in the current page.
            if (this.records && this.records.length > 0) {
                this.prevUids.push(this.records[0].uid + 1); // +1: because the query will exclude the specified uid itself.
            }

            this.records = null;
            try {
                const uid = this.until_uid;
                const json = await $.callPostApi(this, '/api/v1/finance/history?uid=' + encodeURIComponent(uid));
                this.records = json.data;
                if (json.data && json.data.length) {
                    this.until_uid = json.data[json.data.length - 1].uid;
                }
                this.hasMoreRecords = json.data.length === json.page_size;
            } catch (err) {
                console.error(`ERROR: ${err}`);
                $.top_error(this.$t('general.http_error'));
            }
        },

        goPrevPageAsync: async function () {
            if (this.prevUids.length > 0) {
                this.records = null;

                const uid = this.prevUids.pop();
                if (!isNaN(uid) && uid > 0) {
                    try {
                        console.log(`### start from ${uid}`);
                        const json = await $.callPostApi(this, '/api/v1/finance/history?uid=' + encodeURIComponent(uid));

                        this.records = json.data;
                        if (json.data && json.data.length) {
                            this.until_uid = json.data[json.data.length - 1].uid;
                        }
                        this.hasMoreRecords = json.data.length === json.page_size;
                    } catch (err) {
                        console.error(`ERROR: ${err}`);
                        $.top_error(this.$t('general.http_error'));
                    }
                }
            }
        },

        getTokenName: function (token) {
            switch (token) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token;
            }
        },

        translate: function (type, desp) {
            if (type < 100) {
                return this.$t(bct_map[type] === true ? 'bct.' + type : 'bct.others');
            } else {
                return (this.sysconfig.customBcts[this.lang] || {})[type] || desp;
            }
        }
    }
};
</script>
<style scoped>
.settings-page .justify-content-center button{
   border: 1px solid #006CFF;
   color: #006CFF;
}
</style>