<template>
    <div class="content-bottom bg-content">
        <div class="mod-head bg-title">
            <span>{{ $t('exchange.orders_open_orders') }}</span>
            <router-link to="/exchange/orders" class="float-right">{{ $t('exchange.orders_all_orders') }}</router-link>
        </div>
        <div class="mod-body">
            <section class="order-container">
                <table class="table text-center">
                    <thead>
                        <tr class="text-label">
                            <th class="hidden-sm">{{ $t('exchange.orders_create_time') }}</th>
                            <th class="hidden-sm">{{ $t('exchange.orders_symbol') }}</th>
                            <th>{{ $t('exchange.orders_side') }}</th>
                            <th class="text-right">{{ $t('exchange.orders_price_long') }}</th>
                            <th class="text-right">{{ $t('exchange.orders_amount') }}</th>
                            <th class="text-right">{{ $t('exchange.orders_executed_long') }}</th>
                            <th class="text-right hidden-sm">{{ $t('exchange.orders_avg_price') }}</th>
                            <th class="text-right">{{ $t('exchange.orders_action') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-bind:data-order-id="order.orderId" v-for="order in open_orders" :key="order.orderId" class="text-content">
                            <td class="hidden-sm">{{ new Date(order.timeCreated).formatDateTime() }}</td>
                            <td class="hidden-sm">{{ order.symbolName }}</td>
                            <td>
                                <span class="color-up" v-if="order.type === 1">{{ $t('exchange.orders_buy') }}</span>
                                <span class="color-down" v-else>{{ $t('exchange.orders_sell') }}</span>
                            </td>
                            <td class="text-right">{{ order.limitedPrice }}</td>
                            <td class="text-right">
                                {{ order.amount }} <small class="text-muted d-none">{{ order.currency }}</small>
                            </td>
                            <td class="text-right">{{ order.completedAmount }}</td>
                            <td class="text-right hidden-sm">{{ order.avgPrice }}</td>
                            <td class="text-right">
                                <a href="javascript:" class="cancel-button" @click="cancelOrder(order.orderId)">{{ $t('general.cancel') }}</a>
                            </td>
                        </tr>
                        <tr v-if="open_orders.length === 0">
                            <td colspan="8">
                                <div class="no-data-indicator text-label">{{ $t('general.no_data') }}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
    </div>
</template>

<script>
let _order_sync_timer = 0;
let _destroyed = true;

export default {
    props: ['symbol'],

    data() {
        return { open_orders: [], destroyed: false };
    },

    created() {
        _destroyed = false;

        // Clear existing timer
        this.delaySyncOrders();
    },

    beforeDestroy() {
        _destroyed = true;
        clearTimeout(_order_sync_timer);
    },

    methods: {
        syncOrders: function () {
            const self = this;

            $.callPostApi(self, '/api/v1/exchange/activeorders?symbol=' + encodeURIComponent(self.symbol.metadata.id))
                .then((json) => {
                    // json will be undefined for unauthenticated requests.
                    if (json) {
                        if (json.errcode === 0) {
                            self.open_orders = json.data;
                        } else {
                            console.error(`ERROR: ${json.errmsg}`);
                        }
                    }
                })
                .then(() => {
                    // Stop existing timer
                    clearTimeout(_order_sync_timer);

                    if (_destroyed === false) {
                        _order_sync_timer = setTimeout(() => {
                            self.syncOrders();
                        }, 6000);
                    }
                });
        },

        delaySyncOrders: function () {
            // Clear existing timer
            clearTimeout(_order_sync_timer);

            // Read open orders in a small delay
            const self = this;
            _order_sync_timer = setTimeout(() => {
                self.syncOrders();
            }, 250);
        },

        /**
         * Cancel an order for the specified id.
         */
        cancelOrder: function (id) {
            const self = this;

            $.callPostApi(self, '/api/v1/exchange/cancelorder?id=' + encodeURIComponent(id))
                .then((json) => {
                    // json will be undefined for unauthenticated requests.
                    if (json) {
                        if (json.errcode === 0) {
                            $.top_alert(self.$t('general.operation_succeeded'));

                            self.delaySyncOrders();
                        } else {
                            $.top_error(json.errmsg || self.$t('general.operation_error'));
                        }
                    }
                })
                .then(() => {
                    if (self.destroyed === false) {
                        setTimeout(() => {
                            self.syncOrders();
                        }, 6000);
                    }
                });
        }
    }
};
</script>
<style scoped>
.mod-head{
    display: flex;
}
.mod-head .float-right{
    flex: 1;
    text-align: right;
    color: #2E86FE;
}
.order-container .cancel-button{
    color: #2e86fe;
}
</style>