<template>
    <div class="form-group row">
        <div class="col">
            <label class="form-label">{{ title }}</label>
            <div class="row">
                <div class="col-md-12">
                    <label class="thumbnail" @click="remote">
                        <div ref="imagePreview" class="img-preview">
                            <svg width="300" height="200" xmlns="http://www.w3.org/2000/svg" class="img-responsive">
                                <g style="font-size: 12px">
                                    <text
                                        x="150"
                                        y="90"
                                        text-anchor="middle"
                                        font-size="28px"
                                        font-family="robotoregular,Hiragino Sans GB,Heiti SC,Microsoft YaHei,Helvetica,Arial,serif"
                                        fill="#999"
                                        ref="textElement">
                                        <!-- Dynamic text goes here -->
                                    </text>
                                    <text
                                        x="150"
                                        y="120"
                                        text-anchor="middle"
                                        font-family="robotoregular,Hiragino Sans GB,Heiti SC,Microsoft YaHei,Helvetica,Arial,serif"
                                        fill="#999"
                                        ref="textElement2">
                                    </text>
                                </g>
                            </svg>
                        </div>

                        <div class="d-none">
                            <input ref="fileInput" type="file" @change="fileChanged" :name="name" accept="image/png, image/jpeg" />
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['name', 'title'],

    data() {
        return {
            maxTextWidth: 250,  // Maximum width before wrapping text
            truncatedTitle: '',
            truncatedSubtitle: ''
        };
    },

    mounted() {
        this.adjustText();
    },
    updated() {
        this.adjustText();
    },

    methods: {
        remote() {
            const previewDiv = this.$refs.imagePreview;
            const img_holder = $(previewDiv);
            if (img_holder.find('svg').hasClass('img-responsive') && img_holder.find('svg').hasClass('d-none')) {
                img_holder.find('svg').removeClass('img-responsive');
                img_holder.find('svg').removeClass('d-none');
                img_holder.css('background-image', 'none');
            }
        },

        fileChanged(e) {
            const fileInput = this.$refs.fileInput;
            const file = fileInput.files[0];
            if (file.size > 10 * 1024 * 1024) {
                fileInput.value = '';
                $.top_error(this.$t('idverification.err_too_big_file', { fileSize: Math.ceil(file.size / 1024.0) }));
                return false;
            }

            let imgPath = fileInput.value;
            let ext = imgPath.substring(imgPath.lastIndexOf('.') + 1).toLowerCase();

            const previewDiv = this.$refs.imagePreview;
            const img_holder = $(previewDiv);

            if (['gif', 'png', 'jpg', 'jpeg'].includes(ext)) {
                if (typeof FileReader !== 'undefined') {
                    let reader = new FileReader();
                    reader.onload = function (e) {
                        img_holder.find('svg').attr('class', 'img-responsive d-none');
                        img_holder.css('background-image', 'url(' + e.target.result + ')');
                    };
                    reader.readAsDataURL(file);
                } else {
                    console.log('This browser does not support FileReader.');
                }
            } else {
                $.top_error(this.$t('idverification.err_invalid_file_type'));
                fileInput.value = '';
                img_holder.find('svg').attr('class', 'img-responsive');
                img_holder.css('background-image', '');
            }
        },

        //svg内text文本换行
        adjustText() {
            const textElement = this.$refs.textElement;
            const textElement2 = this.$refs.textElement2;
            const maxWidth = this.maxTextWidth;

            const wrapText = (textNode, text) => {
                const words = text.split(' ');
                let line = '';
                let lines = [];
                
                textNode.textContent = ''; // Clear existing text
                
                words.forEach(word => {
                    textNode.textContent = line + word + ' ';
                    const bbox = textNode.getBBox();
                    if (bbox.width > maxWidth) {
                        lines.push(line.trim());
                        line = word + ' ';
                    } else {
                        line += word + ' ';
                    }
                });
                lines.push(line.trim()); // Add the last line
                
                // Clear existing tspans and add new ones
                textNode.innerHTML = lines.map((line, i) => 
                    `<tspan x="${textNode.getAttribute('x')}" dy="${i > 0 ? '1.2em' : '0'}">${line}</tspan>`
                ).join('');
            };

            wrapText(textElement, '+');
            wrapText(textElement2, this.title); // Replace 'Additional Text' with your actual subtitle if needed
        }
    }
};
</script>

<style scoped>
.img-preview {
    background-size: contain;
    background-position: 50%;
}
</style>
