import { render, staticRenderFns } from "./BottomNav-4.2.vue?vue&type=template&id=bc097480&scoped=true"
import script from "./BottomNav-4.2.vue?vue&type=script&lang=js"
export * from "./BottomNav-4.2.vue?vue&type=script&lang=js"
import style1 from "./BottomNav-4.2.vue?vue&type=style&index=1&id=bc097480&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc097480",
  null
  
)

export default component.exports